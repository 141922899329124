import { type ComponentType, createContext, type ReactNode, useContext, useEffect, useState } from 'react';
import getConfig from 'next/config';
import type { variant } from '@finn-no/favorite-heart-component/dist/types';

const {
    publicRuntimeConfig: { favouriteBaseUri },
} = getConfig();

export interface FavoriteApiContextValue {
    FavoriteComponent: ComponentType<FavoriteComponentProps> | null;
}

export type FavoriteComponentProps = {
    itemId: number;
    isMobile: boolean;
    isOpen: boolean;
    variant: variant;
    itemType: string;
};

export const FavoriteApiContext = createContext<FavoriteApiContextValue | undefined>(undefined);

export const useFavoriteApiContext = () => {
    const context = useContext(FavoriteApiContext);
    if (!context) {
        console.error('useFavoriteApiContext was called without FavoriteApiContext');
        return { FavoriteComponent: null };
    }
    return context;
};

interface FavoriteApiProviderProps {
    loginId: number | undefined;
    children: ReactNode;
}

export const FavoriteApiProvider = ({ loginId, children }: FavoriteApiProviderProps) => {
    // biome-ignore lint/suspicious/noExplicitAny: Not able to find a sensible type here, probably related to the dynamic import
    const [FavoriteComponent, setFavoriteComponent] = useState<ComponentType<any> | null>(null);

    useEffect(() => {
        const loadFavoriteComponent = async () => {
            try {
                const favoriteImport = await import('@finn-no/favorite-heart-component');
                const apiFactoryFunction = favoriteImport.default;

                if (apiFactoryFunction) {
                    const component = apiFactoryFunction({ userId: loginId, baseUri: favouriteBaseUri });
                    setFavoriteComponent(() => component);
                } else {
                    setFavoriteComponent(null);
                    console.error('Factory function is not available');
                }
            } catch (error) {
                console.error('Error initializing Favorite API:', error);
                setFavoriteComponent(null);
            }
        };

        loadFavoriteComponent();
    }, []);

    const contextValue: FavoriteApiContextValue = { FavoriteComponent: FavoriteComponent };

    return <FavoriteApiContext.Provider value={contextValue}>{children}</FavoriteApiContext.Provider>;
};
