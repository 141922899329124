import type { variant } from '@finn-no/favorite-heart-component/dist/types';
import { useFavoriteApiContext } from '@/context/FavoriteApiProvider';

export type FavoriteButtonProps = {
    itemId: number;
    isMobile: boolean;
    heartVariant: variant;
    showModal: boolean;
};

export const FavoriteButton = ({ itemId, isMobile, heartVariant, showModal }: FavoriteButtonProps) => {
    const { FavoriteComponent } = useFavoriteApiContext();

    return FavoriteComponent ? (
        <span data-testid="favoriteButton">
            <FavoriteComponent itemType="Ad" variant={heartVariant} itemId={itemId} isMobile={isMobile} isOpen={showModal} />
        </span>
    ) : heartVariant === 'button' ? (
        <button
            type="button"
            className="button button-secondary button--has-icon inline-flex items-center py-2 pl-10 pr-16"
            aria-haspopup="dialog"
            aria-pressed="false"
            data-testid="favoriteButtonFallback">
            <FallbackHeartIcon />
            Legg til favoritt
        </button>
    ) : null;
};

const FallbackHeartIcon = () => (
    <svg width="40" height="40" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" aria-hidden={true} focusable={false}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.992 14.9347C20.5477 13.7169 19.1151 13.006 17.6828 13.006C15.8582 13.006 14.3024 13.5866 13.0422 14.8201C10.3192 17.4824 10.3193 21.8225 13.0423 24.486C13.2939 24.7324 14.5428 26.0342 16.4393 28.0232C17.1928 28.8135 17.999 29.6604 18.8044 30.5072L19.8776 31.6363C19.9399 31.6983 19.9816 31.7422 20.0423 31.8084L20.0485 31.8151C20.1347 31.9097 20.1927 31.9733 20.2388 32.0231C20.8347 32.6658 21.2596 32.9834 21.9926 33C22.7052 33 23.1385 32.6786 23.746 32.0225C23.7918 31.973 23.8496 31.9096 23.9352 31.8156L23.9423 31.8078C24.0028 31.7418 24.0445 31.6979 24.0773 31.6657C24.1267 31.6152 24.1657 31.5754 24.2046 31.5356C24.3487 31.3883 24.4927 31.2409 25.1697 30.548L27.5355 28.1268C29.5603 26.0548 30.9387 24.6446 30.963 24.6204C33.68 21.9626 33.68 17.6222 30.9567 14.96C29.6187 13.6507 28.084 13 26.2778 13C24.8775 13 23.3939 13.7403 21.992 14.9347ZM22.0677 30.9181C22.0409 30.9477 22.0158 30.9749 21.9923 31C21.9686 30.9748 21.9433 30.9473 21.9163 30.9175C21.878 30.8752 21.8285 30.8198 21.7365 30.7167L21.7208 30.6991C21.6313 30.5994 21.5624 30.5253 21.4914 30.4541C21.489 30.4515 21.4868 30.4491 21.4847 30.4467C21.4639 30.4234 21.4427 30.4006 21.2157 30.1564L20.4084 29.2883C19.5731 28.3904 18.7369 27.4924 17.9349 26.6325C15.9431 24.4969 14.6685 23.1386 14.3757 22.8453C12.5416 21.0114 12.5416 18.0649 14.3754 16.2322C15.2134 15.3934 16.228 15.0064 17.526 15.0064C18.5157 15.0064 19.8039 15.7806 21.1767 17.1348L22.0131 17.9599L22.8369 17.1212C24.117 15.8181 25.476 15 26.4339 15C27.6779 15 28.6775 15.4333 29.6248 16.381C31.4584 18.2134 31.4584 21.16 29.6249 22.9936C29.5782 23.0409 28.3422 24.3337 26.0393 26.7429L22.4741 30.4731C22.4213 30.5257 22.3524 30.5999 22.263 30.6996L22.2452 30.7196C22.1546 30.8213 22.1056 30.8762 22.0677 30.9181Z"
            fill="#0063FB"
        />
    </svg>
);
